import React, { useEffect, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DetailPopup from '../components/DetailPopup';

const MyFavoritesPage = ({ supabase, user }) => {
  const [favorites, setFavorites] = useState([]);
  const [filteredFavorites, setFilteredFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchFavorites = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const { data: favoritesData, error: favoritesError } = await supabase
          .from('favorites')
          .select('*')
          .eq('user_id', user.id);

        if (favoritesError) throw favoritesError;

        const detailedFavorites = await Promise.all(favoritesData.map(async (favorite) => {
          const { data, error } = await supabase
            .from(favorite.item_type.toLowerCase() + 's')
            .select('*')
            .eq('id', favorite.item_id)
            .single();

          if (error) throw error;

          return { ...data, category: favorite.item_type };
        }));

        setFavorites(detailedFavorites);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, [supabase, user]);

  useEffect(() => {
    setFilteredFavorites(
      favorites.filter(favorite =>
        favorite.airport_code.toUpperCase().includes(searchTerm.toUpperCase())
      )
    );
  }, [searchTerm, favorites]);

  const handleRowClick = (favorite) => {
    setSelectedItem(favorite);
  };

  const handleClosePopup = () => {
    setSelectedItem(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (!user) {
    return (
      <Box className="max-w-2xl mx-auto">
        <Typography>Please log in to view your favorites.</Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box className="max-w-2xl mx-auto">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="max-w-2xl mx-auto">
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box className="max-w-2xl mx-auto">
      <Typography variant="h5" gutterBottom>
        My Favorites
      </Typography>
      <TextField
        size="small"
        variant="outlined"
        placeholder="Search airport"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: '200px', mb: 2 }}
      />
      {filteredFavorites.length === 0 ? (
        <Typography>No favorites found for the given airport code.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Airport Code</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFavorites.map((favorite, index) => (
                <TableRow 
                  key={index}
                  onClick={() => handleRowClick(favorite)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{favorite.category}</TableCell>
                  <TableCell>{favorite.airport_code}</TableCell>
                  <TableCell>{favorite.name}</TableCell>
                  <TableCell>{favorite.address}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DetailPopup
        item={selectedItem}
        onClose={handleClosePopup}
        supabase={supabase}
        user={user}
      />
    </Box>
  );
};

export default MyFavoritesPage;
