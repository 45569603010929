import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Search from '../components/Search';
import DetailPopup from '../components/DetailPopup';

function SearchPage({ supabase, user }) {
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleItemClick = useCallback((item) => {
    setSelectedItem(item);
  }, []);

  const handleClosePopup = useCallback(() => {
    setSelectedItem(null);
    // Reset the URL when closing the popup
    navigate('/search', { replace: true });
  }, [navigate]);

  return (
    <div className="container mx-auto px-4 max-w-4xl">
      <Search 
        onItemClick={handleItemClick} 
        supabase={supabase} 
        location={location}
        setSelectedItem={setSelectedItem}
        user={user}
        navigate={navigate}  // Pass navigate function to Search component
      />
      {selectedItem && (
        <DetailPopup 
          item={selectedItem} 
          onClose={handleClosePopup} 
          supabase={supabase}
          user={user}
        />
      )}
    </div>
  );
}

export default React.memo(SearchPage);
