import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ResultCard from './ResultCard';
import { Button, Box, Select, MenuItem, FormControl, InputLabel, Typography, Snackbar, CircularProgress, Link } from '@mui/material';
import { createClient } from '@supabase/supabase-js';
import AirportAutocomplete from './AirportAutocomplete';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import './LeafletDarkTheme.css';
import './LeafletCustom.css'; // Add this import
import planeIcon from '../assets/Plane Marker.png';
import hotelIcon from '../assets/Hotel Marker.png';
import forkIcon from '../assets/Fork Marker.png';
import attractionIcon from '../assets/Attraction Marker.png';
import DetailPopup from './DetailPopup';
import RecentActivity from './RecentActivity'; // Import the new component

const Search = ({ onItemClick, supabase, location, setSelectedItem, user }) => {
  const navigate = useNavigate();

  // Parse initial search params from URL
  const initialParams = queryString.parse(location.search);

  const [code, setCode] = useState('');
  const [category, setCategory] = useState('all');
  const [filters, setFilters] = useState({  
    priceRange: '',
    rating: '',
    walkRating: '',
    internetSpeed: '',
  });
  const [results, setResults] = useState(null);  // Add this line
  const [mapCenter, setMapCenter] = useState([39.8283, -98.5795]); // Center of US
  const [mapZoom, setMapZoom] = useState(4);
  const [airportCoords, setAirportCoords] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [mapBounds, setMapBounds] = useState(null);
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMapItem, setSelectedMapItem] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [totalLocations, setTotalLocations] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  // Custom icons
  const createIcon = (iconUrl) => new L.Icon({
    iconUrl,
    iconSize: [25, 25],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const icons = {
    airport: createIcon(planeIcon),
    hotel: createIcon(hotelIcon),
    restaurant: createIcon(forkIcon),
    attraction: createIcon(attractionIcon),
  };

  // Add this useEffect for debugging
  useEffect(() => {
    console.log('Current filters:', filters);
  }, [filters]);

  useEffect(() => {
    // Comment out or remove these lines to stop manual URL navigation
    // const searchParams = {
    //   code,
    //   category,
    //   ...filters
    // };
    // Remove empty values
    // Object.keys(searchParams).forEach(key => !searchParams[key] && delete searchParams[key]);
    
    // navigate(`/search?${queryString.stringify(searchParams)}`, { replace: true });
  }, [code, category, filters, navigate]);

  useEffect(() => {
    // Perform search when URL parameters change
    if (code) {
      handleSearch();
    }
  }, [location.search]);

  useEffect(() => {
    if (code) {
      geocodeAirport(code).then(coords => {
        if (coords) {
          setMapCenter(coords);
          setMapZoom(12);
          setAirportCoords(coords);
        } else {
          // Handle case where coordinates are not found
          setSnackbarMessage('Airport coordinates not found');
          setOpenSnackbar(true);
        }
      });
    }
  }, [code]);

  useEffect(() => {
    if (mapReady && mapRef.current && results && airportCoords) {
      const map = mapRef.current;
      const newMarkers = [];
      const bounds = L.latLngBounds();
      
      // Include airport coordinates in bounds
      if (airportCoords) {
        bounds.extend(airportCoords);
      }

      // Collect and extend bounds with all result coordinates
      Object.entries(results).forEach(([category, items]) => {
        items.forEach((item) => {
          if (item.coordinates) {
            bounds.extend(item.coordinates);
            const marker = L.marker(item.coordinates, { icon: icons[category.slice(0, -1)] });
            
            // Create a clickable popup content
            const popupContent = document.createElement('div');
            const nameElement = document.createElement('a');
            nameElement.href = '#';
            nameElement.textContent = item.name;
            nameElement.onclick = (e) => {
              e.preventDefault();
              setSelectedMapItem(item);
            };
            popupContent.appendChild(nameElement);

            marker.bindPopup(popupContent);
            
            newMarkers.push({ marker, item });
          }
        });
      });

      // Fit map to all markers
      if (bounds.isValid()) {
        map.fitBounds(bounds.pad(0.1));
      }

      // Clear existing markers
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer);
        }
      });

      // Add airport marker
      if (airportCoords) {
        const airportMarker = L.marker(airportCoords, { icon: icons.airport })
          .addTo(map)
          .bindPopup(code);
        newMarkers.unshift({ marker: airportMarker, item: { name: code, category: 'Airport' } });
      }

      // Add all markers at once
      newMarkers.forEach(({ marker }) => {
        marker.addTo(map);
      });

      setMarkers(newMarkers);
      setIsLoading(false);
    }
  }, [results, airportCoords, mapReady]);

  useEffect(() => {
    // Check if we need to reset the search
    if (location.state && location.state.reset) {
      setCode('');
      setCategory('all');
      setFilters({
        priceRange: '',
        rating: '',
        walkRating: '',
        internetSpeed: '',
      });
      setResults(null);
      setMapCenter([39.8283, -98.5795]); // Center of US
      setMapZoom(4);
      setAirportCoords(null);
      setMarkers([]);
      
      // Clear the state to prevent repeated resets
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sharedCategory = searchParams.get('category');
    const sharedId = searchParams.get('id');

    if (sharedCategory && sharedId) {
      setCategory(sharedCategory);
      fetchSharedItem(sharedCategory, sharedId);
    }
  }, [location.search]);

  useEffect(() => {
    if (supabase) {
      fetchTotalLocations();
      fetchTotalUsers();
    }
  }, [supabase]);

  const fetchTotalLocations = async () => {
    if (!supabase) return;

    try {
      const tables = ['hotels', 'restaurants', 'attractions'];
      let total = 0;

      for (const table of tables) {
        const { count, error } = await supabase
          .from(table)
          .select('*', { count: 'exact', head: true });

        if (error) throw error;
        total += count || 0;
      }

      setTotalLocations(total);
    } catch (error) {
      console.error('Error fetching total locations:', error);
    }  
  };
//Fetch total users
  const fetchTotalUsers = async () => {
    if (!supabase) return;

    try {
      const { count, error } = await supabase
        .from('profiles')
        .select('*', { count: 'exact', head: true });

      if (error) throw error;

      setTotalUsers(count || 0);
    } catch (error) {
      console.error('Error fetching total users:', error);
    }  
  };
  // Add this function to reset the URL
  const resetUrl = () => {
    navigate('/search', { replace: true });
  };

  // Modify the handleSearch function
  const handleSearch = async () => {
    setIsLoading(true);
    setHasSearched(true);
    try {
      // Clear previous results
      setResults(null);
      setMarkers([]);

      const fetchCategory = async (category) => {
        const { data, error } = await supabase
          .from(category)
          .select('*')
          .eq('airport_code', code);
        
        if (error) throw error;
        return data;
      };

      let searchResults = {};

      if (category === 'all' || category === 'hotels') {
        searchResults.hotels = await fetchCategory('hotels');
      }
      if (category === 'all' || category === 'restaurants') {
        searchResults.restaurants = await fetchCategory('restaurants');
      }
      if (category === 'all' || category === 'attractions') {
        searchResults.attractions = await fetchCategory('attractions');
      }

      // Geocode addresses
      for (const categoryKey in searchResults) {
        searchResults[categoryKey] = await Promise.all(
          searchResults[categoryKey].map(async (item) => {
            const coords = await geocodeAddress(item.address);
            return { ...item, coordinates: coords };
          })
        );
      }

      console.log('Search results:', searchResults);
      
      const filteredResults = filterResults(searchResults);
      setResults(filteredResults);

      // Geocode the new airport and set coordinates
      const airportCoords = await geocodeAirport(code);
      if (airportCoords) {
        setMapCenter(airportCoords);
        setMapZoom(12);
        setAirportCoords(airportCoords);
      }

      // Reset the URL
      resetUrl();
    } catch (error) {
      console.error('Error searching:', error);
      setSnackbarMessage('Error occurred while searching');
      setOpenSnackbar(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Modify the fetchSharedItem function
  const fetchSharedItem = async (category, id) => {
    if (!supabase) return;

    try {
      const tableName = category.toLowerCase() + 's';
      
      const { data, error } = await supabase
        .from(tableName)
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      if (data) {
        const item = { ...data, category: category.charAt(0).toUpperCase() + category.slice(1) };
        setResults({ [category]: [item] });
        setSelectedItem(item);
        onItemClick(item);

        if (item.airport_code) {
          setCode(item.airport_code);
          const coords = await geocodeAirport(item.airport_code);
          if (coords) {
            setMapCenter(coords);
            setMapZoom(12);
            setAirportCoords(coords);
          }
        }

        // Reset category to 'All' and perform a new search
        setCategory('all');
        handleSearch(); // Call handleSearch without parameters
      }
    } catch (error) {
      console.error('Error fetching shared item:', error);
      setSnackbarMessage('Error loading shared item. Please try again.');
      setOpenSnackbar(true);
    }
  };

  // Modify the geocodeAirport function
  const geocodeAirport = async (airportCode) => {
    if (!supabase || !airportCode) return null;
    try {
      const { data, error } = await supabase
        .from('Airports')
        .select('latitude, longitude')
        .or(`icao.eq.${airportCode},iata.eq.${airportCode}`)
        .single();

      if (error) {
        if (error.code === 'PGRST116') {
          console.error('Airport not found for code:', airportCode);
          return null;
        }
        throw error;
      }

      if (data && data.latitude && data.longitude) {
        console.log('Found airport:', data);
        return [parseFloat(data.latitude), parseFloat(data.longitude)];
      } else {
        console.error('Airport coordinates not found for code:', airportCode);
        return null;
      }
    } catch (error) {
      console.error('Error fetching airport coordinates:', error);
      return null;
    }
  };

  const geocodeAddress = async (address) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
        params: {
          q: address,
          format: 'json',
          limit: 1
        }
      });
      if (response.data && response.data.length > 0) {
        return [parseFloat(response.data[0].lat), parseFloat(response.data[0].lon)];
      }
      return null;
    } catch (error) {
      console.error('Error geocoding address:', error);
      return null;
    }
  };

  const filterResults = (results) => {
    const filteredResults = {};

    Object.keys(results).forEach(categoryKey => {
      filteredResults[categoryKey] = results[categoryKey].filter(item => {
        const ratingFilter = category === 'all' || !filters.rating || item.rating >= parseInt(filters.rating);
        let priceFilter = true;
        let walkRatingFilter = true;
        let internetSpeedFilter = true;

        if (filters.priceRange) {
          if (categoryKey === 'hotels') {
            priceFilter = item.price_range === filters.priceRange;
          } else {
            const priceRanges = ['$', '$$', '$$$', '$$$$'];
            const maxPriceIndex = priceRanges.indexOf(filters.priceRange);
            priceFilter = priceRanges.indexOf(item.price_range) <= maxPriceIndex;
          }
        }

        if (categoryKey === 'hotels') {
          if (filters.walkRating) {
            walkRatingFilter = item.walk_rating >= parseInt(filters.walkRating);
          }

          if (filters.internetSpeed) {
            if (filters.internetSpeed === 'No Internet') {
              internetSpeedFilter = item.internet_speed === 'No Internet';
            } else {
              const speedValue = parseInt(filters.internetSpeed);
              const itemSpeedValue = item.internet_speed === 'No Internet' ? 0 : 
                item.internet_speed === '100Mbps+' ? 100 :
                parseInt(item.internet_speed);
              internetSpeedFilter = itemSpeedValue >= speedValue;
            }
          }
        }

        return ratingFilter && priceFilter && walkRatingFilter && internetSpeedFilter;
      });
    });

    return filteredResults;
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const renderFilters = () => {
    const showPriceRange = category !== 'all';
    const isPriceRangeMaxPrice = category === 'restaurants' || category === 'attractions';
    const showRating = category !== 'all';  // New line

    const renderStars = (count) => '★'.repeat(count) + '★'.repeat(5 - count).replace(/★/g, '☆');

    return (
      <>
        {showRating && (  // Wrap the Rating FormControl with this condition
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel>Rating</InputLabel>
            <Select 
              name="rating" 
              value={filters.rating} 
              onChange={handleFilterChange}
              renderValue={(selected) => selected ? renderStars(parseInt(selected)) : "Any"}
            >
              <MenuItem value="">Any</MenuItem>
              {[1, 2, 3, 4, 5].map(rating => (
                <MenuItem key={rating} value={rating.toString()}>{renderStars(rating)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {showPriceRange && (
          <FormControl fullWidth margin="normal" size="small">
            <InputLabel>{isPriceRangeMaxPrice ? "Max Price" : "Price Range"}</InputLabel>
            <Select 
              name="priceRange" 
              value={filters.priceRange} 
              onChange={handleFilterChange}
              renderValue={(selected) => selected || "Any"}
            >
              <MenuItem value="">Any</MenuItem>
              {isPriceRangeMaxPrice ? (
                ['$', '$$', '$$$', '$$$$'].map(price => (
                  <MenuItem key={price} value={price}>{price}</MenuItem>
                ))
              ) : (
                [
                  <MenuItem key="$100-175" value="$100-175">$100 - $175</MenuItem>,
                  <MenuItem key="$176-250" value="$176-250">$176 - $250</MenuItem>,
                  <MenuItem key="$251-350" value="$251-350">$251 - $350</MenuItem>,
                  <MenuItem key="$351+" value="$351+">$351+</MenuItem>
                ]
              )}
            </Select>
          </FormControl>
        )}
        {category === 'hotels' && (
          <>
            <FormControl fullWidth margin="normal" size="small">
              <InputLabel>Walk Rating</InputLabel>
              <Select 
                name="walkRating" 
                value={filters.walkRating} 
                onChange={handleFilterChange}
                renderValue={(selected) => selected ? renderStars(parseInt(selected)) : "Any"}
              >
                <MenuItem value="">Any</MenuItem>
                {[1, 2, 3, 4, 5].map(rating => (
                  <MenuItem key={rating} value={rating.toString()}>{renderStars(rating)}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal" size="small">
              <InputLabel>Internet Speed</InputLabel>
              <Select 
                name="internetSpeed" 
                value={filters.internetSpeed} 
                onChange={handleFilterChange}
                renderValue={(selected) => selected || "Any"}
              >
                <MenuItem value="">Any</MenuItem>
                <MenuItem value="No Internet">No Internet</MenuItem>
                <MenuItem value="5Mbps+">5Mbps+</MenuItem>
                <MenuItem value="15Mbps+">15Mbps+</MenuItem>
                <MenuItem value="30Mbps+">30Mbps+</MenuItem>
                <MenuItem value="50Mbps+">50Mbps+</MenuItem>
                <MenuItem value="100Mbps+">100Mbps+</MenuItem>
              </Select>
            </FormControl>
          </>
        )}
        <div className="flex justify-center mt-4 mb-6">
          <Button 
            variant="contained" 
            onClick={handleSearch} 
            size="small"
            className="w-1/4"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Search'
            )}
          </Button>
        </div>
        <div className="flex justify-center mt-2 mb-6"> {/* Added mb-6 for bottom margin */}
          <Box 
            className="bg-gray-800 px-3 py-1 rounded-md"
            sx={{ border: '1px solid', borderColor: 'primary.main' }}
          >
            <Typography variant="caption" className="text-gray-300">
              {totalLocations.toLocaleString()} Locations & {totalUsers.toLocaleString()} Users added and counting!
            </Typography>
          </Box>
        </div>
      </>
    );
  };

  const ResultCard = ({ item, onClick }) => {
    const getGoogleMapsUrl = (address) => {
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    };

    return (
      <div 
        className="bg-gray-800 p-4 rounded-lg shadow-md cursor-pointer hover:bg-gray-700 transition-colors duration-200"
        onClick={() => onClick(item)}
      >
        <h3 className="text-xl font-semibold mb-2">{item.name}</h3>
        <p className="text-gray-400 mb-2">
          <Link 
            href={getGoogleMapsUrl(item.address)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()} // Prevent the card's onClick from firing
            className="text-blue-400 hover:text-blue-300"
          >
            {item.address}
          </Link>
        </p>
        <p className="text-yellow-400">{"★".repeat(item.rating) + "☆".repeat(5 - item.rating)}</p>
        {item.category === 'Hotel' && (
          <>
            <p className="text-gray-400">Price Range: {item.price_range}</p>
            <p className="text-gray-400">Walk Rating: {"★".repeat(item.walk_rating) + "☆".repeat(5 - item.walk_rating)}</p>
            <p className="text-gray-400">Internet Speed: {item.internet_speed}</p>
            {item.free_breakfast && <p className="text-green-400">Free Breakfast</p>}
          </>
        )}
        {(item.category === 'Restaurant' || item.category === 'Attraction') && (
          <p className="text-gray-400">Price Range: {item.price_range}</p>
        )}
      </div>
    );
  };

  const renderResults = () => {
    if (!results) return null;

    const allResults = [
      ...(results.hotels || []).map(item => ({ 
        ...item, 
        category: 'Hotel',
        free_breakfast: item.free_breakfast // Make sure this property exists in your API response
      })),
      ...(results.restaurants || []).map(item => ({ ...item, category: 'Restaurant' })),
      ...(results.attractions || []).map(item => ({ ...item, category: 'Attraction' }))
    ];

    return (
      <div className="space-y-5 mt-6 pb-10">
        {allResults.map((item) => (
          <ResultCard
            key={`${item.category}-${item.id}`}
            item={item}
            onClick={onItemClick}
          />
        ))}
      </div>
    );
  };

  const calculateMapBounds = (airport, results) => {
    let points = airport ? [airport] : [];
    
    Object.values(results).forEach(category => {
      category.forEach(item => {
        if (item.coordinates) {
          points.push(item.coordinates);
        }
      });
    });

    if (points.length === 0) return null;

    const latitudes = points.map(p => p[0]);
    const longitudes = points.map(p => p[1]);

    const minLat = Math.min(...latitudes);
    const maxLat = Math.max(...latitudes);
    const minLng = Math.min(...longitudes);
    const maxLng = Math.max(...longitudes);

    // Add a buffer (10% of the range)
    const latBuffer = (maxLat - minLat) * 0.1;
    const lngBuffer = (maxLng - minLng) * 0.1;

    return [
      [minLat - latBuffer, minLng - lngBuffer],
      [maxLat + latBuffer, maxLng + lngBuffer]
    ];
  };

  const MapComponent = () => {
    const map = useMap();
    
    useEffect(() => {
      if (map) {
        mapRef.current = map;
        setMapReady(true);
      }
    }, [map]);

    return null;
  };

  const handleMarkerClick = useCallback((item) => {
    setSelectedItem(item);
  }, []);

  const handleClosePopup = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const renderMap = () => (
    <div className="relative ... border border-gray-700 rounded-sm">
      <MapContainer
        center={mapCenter}
        zoom={mapZoom}
        style={{ height: '400px', width: '100%' }}
        className="dark-theme-map"
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          // Remove the className="custom-tile-layer"
        />
        <MapComponent />
      </MapContainer>
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <CircularProgress />
        </div>
      )}
    </div>
  );

  return (
    <Box className="max-w-2xl mx-auto">
      <Typography variant="h5" gutterBottom className="mb-10">
        Search
      </Typography>
      <AirportAutocomplete
        value={code}
        onChange={(newValue) => setCode(newValue)}
        label="ICAO/IATA Code"
        size="small"
      />
      <FormControl fullWidth margin="normal" size="small">
        <InputLabel>Category</InputLabel>
        <Select value={category} onChange={(e) => setCategory(e.target.value)}>
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="hotels">Hotels</MenuItem>
          <MenuItem value="restaurants">Restaurants</MenuItem>
          <MenuItem value="attractions">Attractions</MenuItem>
        </Select>
      </FormControl>
      {renderFilters()}
      {hasSearched ? (
        renderMap()
      ) : (
        <RecentActivity supabase={supabase} onItemClick={onItemClick} />
      )}
      <div className="space-y-3 mt-6 pb-8">
        {renderResults()}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
      {selectedMapItem && (
        <DetailPopup
          item={selectedMapItem}
          onClose={() => setSelectedMapItem(null)}
          supabase={supabase}
          user={user}
        />
      )}
    </Box>
  );

};

export default Search;
