import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Container, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import SearchPage from './pages/SearchPage';
import SubmitPage from './pages/SubmitPage';
import FAQPage from './pages/FAQPage';
import { createClient } from '@supabase/supabase-js';
import AuthModal from './components/AuthModal';
import MySubmissionsPage from './pages/MySubmissionsPage';
import MyFavoritesPage from './pages/MyFavoritesPage';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import Footer from './components/Footer';
import AccountPage from './pages/AccountPage';
import ResetPasswordPage from './pages/ResetPasswordPage';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

// Create a single instance of the Supabase client
const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

function App() {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setUser(session?.user ?? null);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session?.user ?? null);
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleLogin = async (email, password) => {
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    if (!error) {
      setIsAuthModalOpen(false);
    } else {
      console.error('Login error:', error);
    }
  };

  const handleSignup = async (email, password, username) => {
    try {
      // Step 1: Sign up the user
      const { data: authData, error: authError } = await supabase.auth.signUp({ 
        email, 
        password 
      });

      if (authError) throw authError;

      // Step 2: Get the user's UUID
      const userId = authData.user.id;

      // Step 3: Create a profile for the user
      const { error: profileError } = await supabase
        .from('profiles')
        .insert({ id: userId, username: username });

      if (profileError) throw profileError;

      setIsAuthModalOpen(false);
      // You might want to show a message that they need to verify their email
    } catch (error) {
      console.error('Signup error:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUser(null);
    setUserProfile(null);
  };

  const handleOpenAuthModal = () => {
    setIsAuthModalOpen(true);
  };

  const handleCloseAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  if (!supabase) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <div className="flex flex-col min-h-screen">
          <ResponsiveAppBar 
            user={user} 
            userProfile={userProfile}
            onLogout={handleLogout}
            onOpenAuthModal={handleOpenAuthModal}
          />
          <Container className="flex-grow">
            <Routes>
              <Route path="/" element={<SearchPage supabase={supabase} user={user} />} />
              <Route path="/search" element={<SearchPage supabase={supabase} user={user} />} />
              <Route path="/submit" element={<SubmitPage supabase={supabase} user={user} />} />
              <Route path="/my-submissions" element={<MySubmissionsPage supabase={supabase} user={user} />} />
              <Route path="/my-favorites" element={<MyFavoritesPage supabase={supabase} user={user} />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/account" element={<AccountPage supabase={supabase} user={user} />} />
              <Route path="/reset-password" element={<ResetPasswordPage supabase={supabase} />} />
              {/* Redirect all other routes to home */}
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Container>
          <Footer />
        </div>
        <AuthModal
          isOpen={isAuthModalOpen}
          onClose={handleCloseAuthModal}
          onLogin={handleLogin}
          onSignup={handleSignup}
          supabase={supabase}
        />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
