import React from 'react';
import FAQ from '../components/FAQ';

function FAQPage() {
  return (
    <div className="container mx-auto px-4 max-w-4xl">
      <FAQ />
    </div>
  );
}

export default FAQPage;
