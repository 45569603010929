import React from 'react';
import Submit from '../components/Submit';

function SubmitPage({ user, supabase }) {
  return (
    <div className="container mx-auto px-4 max-w-4xl">
      <Submit user={user} supabase={supabase} />
    </div>
  );
}

export default SubmitPage;
