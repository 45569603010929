import React, { useEffect, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DetailPopup from '../components/DetailPopup';

const MySubmissionsPage = ({ supabase, user }) => {
  const [submissions, setSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSubmissions = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const { data: hotels, error: hotelsError } = await supabase
          .from('hotels')
          .select('*')
          .eq('created_by', user.id);

        const { data: restaurants, error: restaurantsError } = await supabase
          .from('restaurants')
          .select('*')
          .eq('created_by', user.id);

        const { data: attractions, error: attractionsError } = await supabase
          .from('attractions')
          .select('*')
          .eq('created_by', user.id);

        if (hotelsError || restaurantsError || attractionsError) {
          throw new Error('Error fetching submissions');
        }

        const allSubmissions = [
          ...hotels.map(h => ({ ...h, category: 'Hotel' })),
          ...restaurants.map(r => ({ ...r, category: 'Restaurant' })),
          ...attractions.map(a => ({ ...a, category: 'Attraction' }))
        ];

        setSubmissions(allSubmissions);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, [supabase, user]);

  useEffect(() => {
    setFilteredSubmissions(
      submissions.filter(submission =>
        submission.airport_code.toUpperCase().includes(searchTerm.toUpperCase())
      )
    );
  }, [searchTerm, submissions]);

  const handleRowClick = (submission) => {
    setSelectedItem(submission);
  };

  const handleClosePopup = () => {
    setSelectedItem(null);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (!user) {
    return (
      <Box className="max-w-2xl mx-auto">
        <Typography>Please log in to view your submissions.</Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box className="max-w-2xl mx-auto">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className="max-w-2xl mx-auto">
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box className="max-w-2xl mx-auto">
      <Typography variant="h5" gutterBottom>
        My Submissions
      </Typography>
      <TextField
        size="small"
        variant="outlined"
        placeholder="Search airport"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: '200px', mb: 2 }}
      />
      {filteredSubmissions.length === 0 ? (
        <Typography>No submissions found for the given airport code.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Airport Code</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSubmissions.map((submission, index) => (
                <TableRow 
                  key={index} 
                  onClick={() => handleRowClick(submission)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{submission.category}</TableCell>
                  <TableCell>{submission.airport_code}</TableCell>
                  <TableCell>{submission.name}</TableCell>
                  <TableCell>{submission.address}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DetailPopup
        item={selectedItem}
        onClose={handleClosePopup}
        supabase={supabase}
        user={user}
      />
    </Box>
  );
};

export default MySubmissionsPage;
