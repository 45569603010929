import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography, Link } from '@mui/material';

const AuthModal = ({ isOpen, onClose, onLogin, onSignup, supabase }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [resetMessage, setResetMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLogin) {
      if (typeof onLogin === 'function') {
        onLogin(email, password);
      } else {
        console.error('onLogin is not a function');
      }
    } else {
      if (typeof onSignup === 'function') {
        onSignup(email, password, username);
      } else {
        console.error('onSignup is not a function');
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) throw error;
      setResetMessage('Password reset email sent. Check your inbox.');
    } catch (error) {
      console.error('Error sending reset password email:', error);
      setResetMessage('Error sending reset password email. Please try again.');
    }
  };

  const renderLoginSignup = () => (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {!isLogin && (
          <TextField
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        )}
        <Box mt={2}>
          <Link
            component="button"
            variant="body2"
            onClick={() => setResetPasswordMode(true)}
          >
            Forgot Password?
          </Link>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Button type="submit" fullWidth variant="contained" sx={{ mb: 1 }}>
            {isLogin ? 'Login' : 'Sign Up'}
          </Button>
          <Button onClick={() => setIsLogin(!isLogin)} fullWidth sx={{ mb: 1 }}>
            {isLogin ? 'Need an account? Sign Up' : 'Have an account? Login'}
          </Button>
          <Button onClick={onClose} fullWidth>Cancel</Button>
        </Box>
      </DialogActions>
    </form>
  );

  const renderResetPassword = () => (
    <form onSubmit={handleResetPassword}>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {resetMessage && (
          <Typography color="primary" variant="body2" sx={{ mt: 2 }}>
            {resetMessage}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <Button type="submit" fullWidth variant="contained" sx={{ mb: 1 }}>
            Send Reset Email
          </Button>
          <Button onClick={() => setResetPasswordMode(false)} fullWidth sx={{ mb: 1 }}>
            Back to Login
          </Button>
          <Button onClick={onClose} fullWidth>Cancel</Button>
        </Box>
      </DialogActions>
    </form>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen={window.innerWidth < 768}>
      <DialogTitle>{resetPasswordMode ? 'Reset Password' : (isLogin ? 'Login' : 'Sign Up')}</DialogTitle>
      {resetPasswordMode ? renderResetPassword() : renderLoginSignup()}
    </Dialog>
  );
};

export default AuthModal;
