import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import ResultCard from './ResultCard';

const RecentActivity = ({ supabase, onItemClick }) => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchRecentActivities();
  }, [supabase]);

  const fetchRecentActivities = async () => {
    try {
      // Step 1: Fetch recent activities
      const { data: recentActivities, error: activityError } = await supabase
        .from('recent_activity')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(10);

      if (activityError) throw activityError;

      // Step 2: Fetch details for each activity
      const detailedActivities = await Promise.all(recentActivities.map(async (activity) => {
        if (!activity.category || !activity.item_id) {
          console.error('Invalid activity data:', activity);
          return null;
        }

        // Determine the correct table name
        const tableName = getTableName(activity.category);

        // Fetch item details
        const { data: itemData, error: itemError } = await supabase
          .from(tableName)
          .select('*, created_by')
          .eq('id', activity.item_id)
          .single();

        if (itemError) {
          console.error(`Error fetching item details for ${activity.category}:`, itemError);
          return null;
        }

        // Fetch username
        const { data: userData, error: userError } = await supabase
          .from('profiles')
          .select('username')
          .eq('id', activity.user_id)
          .single();

        if (userError) {
          console.error('Error fetching user data:', userError);
          return null;
        }

        // If it's a comment, fetch the specific comment text
        let commentText = null;
        if (activity.action === 'Comment') {
          const { data: commentData, error: commentError } = await supabase
            .from('comments')
            .select('comment_text')
            .eq('user_id', activity.user_id)
            .eq('item_id', activity.item_id)
            .or(`item_type.eq.${activity.category},item_type.eq.${activity.category.toLowerCase()}`)
            .order('created_at', { ascending: false })
            .limit(1);

          if (commentError) {
            console.error('Error fetching comment data:', commentError);
          } else if (commentData && commentData.length > 0) {
            commentText = commentData[0].comment_text;
          }
        }

        return {
          ...activity,
          item: itemData,
          username: userData.username,
          formattedDate: new Date(activity.created_at).toLocaleDateString(),
          commentText
        };
      }));

      // Remove any null activities and ensure uniqueness
      const uniqueActivities = detailedActivities.filter(Boolean).reduce((acc, current) => {
        const x = acc.find(item => 
          item.user_id === current.user_id && 
          item.item_id === current.item_id && 
          item.category === current.category &&
          item.action === current.action
        );
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      setActivities(uniqueActivities);
    } catch (error) {
      console.error('Error fetching recent activities:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTableName = (category) => {
    switch (category.toLowerCase()) {
      case 'hotel':
        return 'hotels';
      case 'restaurant':
        return 'restaurants';
      case 'attraction':
        return 'attractions';
      default:
        throw new Error(`Invalid category: ${category}`);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Recent Activity
      </Typography>
      {activities.length > 0 ? (
        activities.map((activity) => (
          <Box key={`${activity.id}-${activity.created_at}`} mb={2}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {activity.action} by {activity.username} on {activity.formattedDate}
            </Typography>
            {activity.action === 'Comment' && activity.commentText && (
              <Typography variant="body2" color="textSecondary" gutterBottom style={{ marginLeft: '20px', fontStyle: 'italic' }}>
                "{activity.commentText}"
              </Typography>
            )}
            {activity.item && (
              <ResultCard 
                item={{...activity.item, category: activity.category}} 
                onClick={() => onItemClick({...activity.item, category: activity.category})} 
              />
            )}
          </Box>
        ))
      ) : (
        <Typography variant="body1">No recent activity to display.</Typography>
      )}
    </Box>
  );
};

export default RecentActivity;
