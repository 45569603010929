import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { createClient } from '@supabase/supabase-js';

const AirportAutocomplete = ({ value, onChange, label }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [supabase, setSupabase] = useState(null);

  useEffect(() => {
    if (window.REACT_APP_CONFIG) {
      const supabaseUrl = window.REACT_APP_CONFIG.SUPABASE_URL;
      const supabaseKey = window.REACT_APP_CONFIG.SUPABASE_ANON_KEY;
      if (supabaseUrl && supabaseKey) {
        setSupabase(createClient(supabaseUrl, supabaseKey));
      }
    }
  }, []);

  const fetchOptions = useCallback(async (query) => {
    if (query.length >= 2 && supabase) {
      try {
        const { data, error } = await supabase
          .from('Airports')
          .select('icao, iata, airport, country_code')
          .or(`icao.ilike.%${query}%,iata.ilike.%${query}%,airport.ilike.%${query}%`)
          .order('country_code', { ascending: true })
          .limit(20);

        if (error) throw error;

        const sortedData = data.sort((a, b) => {
          // Prioritize exact matches
          const aExactMatch = a.icao === query || a.iata === query;
          const bExactMatch = b.icao === query || b.iata === query;
          if (aExactMatch && !bExactMatch) return -1;
          if (!aExactMatch && bExactMatch) return 1;
          
          // Then prioritize US airports
          if (a.country_code === 'US' && b.country_code !== 'US') return -1;
          if (a.country_code !== 'US' && b.country_code === 'US') return 1;
          
          return 0;
        });

        const filteredOptions = sortedData.filter(airport => airport.icao).slice(0, 5);
        setOptions(filteredOptions);
      } catch (error) {
        console.error('Error fetching airport options:', error);
      }
    } else {
      setOptions([]);
    }
  }, [supabase]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => fetchOptions(inputValue), 300);
    return () => clearTimeout(debounceTimer);
  }, [inputValue, fetchOptions]);

  useEffect(() => {
    if (value && !selectedAirport) {
      fetchOptions(value);
    }
  }, [value, fetchOptions, selectedAirport]);

  useEffect(() => {
    if (value && options.length > 0) {
      const matchingAirport = options.find(option => option.icao === value);
      if (matchingAirport) {
        setSelectedAirport(matchingAirport);
        setInputValue(`${matchingAirport.icao} - ${matchingAirport.iata} - ${matchingAirport.airport}`);
      }
    }
  }, [value, options]);

  const handleChange = (event, newValue) => {
    setSelectedAirport(newValue);
    onChange(newValue ? newValue.icao : '');
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue.toUpperCase());
    if (!newInputValue) {
      setSelectedAirport(null);
      onChange('');
    }
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => `${option.icao} - ${option.iata} - ${option.airport} (${option.country_code})`}
      renderInput={(params) => <TextField {...params} label={label} />}
      value={selectedAirport}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      isOptionEqualToValue={(option, value) => option.icao === value.icao}
      fullWidth
    />
  );
};

export default AirportAutocomplete;
