import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, TextField, Button, Paper, Grid, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AccountPage = ({ supabase, user }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [submissionCount, setSubmissionCount] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [creationDate, setCreationDate] = useState('');
  const [lastLoginDate, setLastLoginDate] = useState('');

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }

    setEmail(user.email || '');
    setUsername(user.user_metadata.username || '');
    setCreationDate(new Date(user.created_at).toLocaleDateString());
    setLastLoginDate(new Date(user.last_sign_in_at).toLocaleString());
    fetchSubmissionCount();
  }, [user, navigate]);

  const fetchSubmissionCount = async () => {
    if (!user) return;

    try {
      const [hotels, restaurants, attractions] = await Promise.all([
        supabase.from('hotels').select('id', { count: 'exact' }).eq('created_by', user.id),
        supabase.from('restaurants').select('id', { count: 'exact' }).eq('created_by', user.id),
        supabase.from('attractions').select('id', { count: 'exact' }).eq('created_by', user.id),
      ]);

      const totalCount = (hotels.count || 0) + (restaurants.count || 0) + (attractions.count || 0);
      setSubmissionCount(totalCount);
    } catch (error) {
      console.error('Error fetching submission count:', error);
    }
  };

  const handleRequestPasswordReset = async () => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`,
      });
      if (error) throw error;
      setSnackbarMessage('Password reset email sent. Please check your inbox.');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setSnackbarMessage('Error requesting password reset. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleUsernameChange = async () => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({ username: username })
        .eq('id', user.id);

      if (error) throw error;
      setSnackbarMessage('Username updated successfully.');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating username:', error);
      setSnackbarMessage('Error updating username. Please try again.');
      setOpenSnackbar(true);
    }
  };

  return (
    <Box className="max-w-2xl mx-auto p-4">
      <Typography variant="h4" gutterBottom className="mb-6">
        Account
      </Typography>
      <Paper elevation={3} className="p-6 mb-6">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              User Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              value={email}
              disabled
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
            />
            <Button onClick={handleUsernameChange} variant="outlined" sx={{ mt: 1 }}>
              Update Username
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Total Submissions: {submissionCount}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Account Created: {creationDate}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Last Login: {lastLoginDate}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} className="p-6 mb-6">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Password Reset
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRequestPasswordReset}
            >
              Request Password Reset Link
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

AccountPage.propTypes = {
  supabase: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default AccountPage;
