import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import FlightIcon from '@mui/icons-material/Flight';
import { useNavigate } from 'react-router-dom';

const pages = ['Search', 'Submit', 'FAQ'];
const settings = ['Account', 'My Submissions', 'My Favorites', 'Logout'];

function ResponsiveAppBar({ user, userProfile, onLogout, onOpenAuthModal }) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = (page) => {
    handleCloseNavMenu();
    navigate(`/${page.toLowerCase()}`);
  };

  const handleSettingClick = (setting) => {
    handleCloseUserMenu();
    switch (setting) {
      case 'Account':
        navigate('/account');
        break;
      case 'My Submissions':
        navigate('/my-submissions');
        break;
      case 'My Favorites':
        navigate('/my-favorites');
        break;
      case 'Logout':
        if (typeof onLogout === 'function') {
          onLogout();
        } else {
          console.error('onLogout is not a function');
        }
        break;
      default:
        console.warn(`Unhandled setting: ${setting}`);
    }
  };

  const userMenu = [
    { name: 'Account', path: '/account' },
    { name: 'My Submissions', path: '/my-submissions' },
    { name: 'My Favorites', path: '/my-favorites' },
    { name: 'Logout', onClick: onLogout },
  ];

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ minHeight: '48px !important', py: 0.5 }}>
          <FlightIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              fontSize: '1.1rem',
            }}
          >
            Never Slam-Click
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() => handleMenuClick(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <FlightIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              fontSize: '1rem',
            }}
          >
            NSC
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleMenuClick(page)}
                sx={{ my: 0.5, color: 'white', display: 'block', px: 1.5, py: 0.5 }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {user ? (
            <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
              {userProfile && (
                <Typography variant="body2" sx={{ mr: 2 }}>
                  {userProfile.username}
                </Typography>
              )}
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar 
                    alt={userProfile?.username || user.email} 
                    src="/static/images/avatar/2.jpg" 
                    sx={{ width: 32, height: 32 }}
                  >
                    {userProfile?.username ? userProfile.username[0].toUpperCase() : user.email[0].toUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userMenu.map((setting) => (
                  <MenuItem key={setting.name} onClick={() => handleSettingClick(setting.name)}>
                    <Typography textAlign="center">{setting.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          ) : (
            <Button 
              color="inherit" 
              onClick={() => {
                if (typeof onOpenAuthModal === 'function') {
                  onOpenAuthModal();
                } else {
                  console.error('onOpenAuthModal is not a function');
                }
              }} 
              sx={{ py: 0.5, px: 1.5 }}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
