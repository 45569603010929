import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Snackbar, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import AirportAutocomplete from './AirportAutocomplete';
import StarRating from './StarRating';
import axios from 'axios';

function Submit({ user, supabase }) {  // Add supabase as a prop
  const [category, setCategory] = useState('hotel'); // Default category
  const [entry, setEntry] = useState({
    category: 'hotel',
    airport_code: '', // ICAO code
    name: '',
    address: '',
    rating: '',
    price_range: '',
    walk_rating: '',
    internet_speed: '',
    free_breakfast: false,
    notes: '',
    link: '', // Add this new field
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [speedTestResult, setSpeedTestResult] = useState(null);
  const [isTestingSpeed, setIsTestingSpeed] = useState(false);
  const [loadingText, setLoadingText] = useState('Testing.');
  const [username, setUsername] = useState('');

  useEffect(() => {
    if (user && supabase) {
      fetchUserProfile();
    } else if (user) {
      setUsername(user.email); // Fallback to email if username is not available
    }
  }, [user, supabase]);

  const fetchUserProfile = async () => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('username')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      if (data && data.username) {
        setUsername(data.username);
      } else {
        setUsername(user.email); // Fallback to email if username is not set
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setUsername(user.email); // Fallback to email on error
    }
  };

  useEffect(() => {
    let interval;
    if (isTestingSpeed) {
      let count = 0;
      interval = setInterval(() => {
        count = (count + 1) % 4;
        setLoadingText('Testing' + '.'.repeat(count + 1));
      }, 500); // Change text every 500ms
    }
    return () => clearInterval(interval);
  }, [isTestingSpeed]);

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setCategory(newCategory);
    setEntry((prev) => ({
      ...prev,
      category: newCategory,
      // Reset fields not relevant to the new category
      walk_rating: '',
      internet_speed: '',
      free_breakfast: false,
      price_range: '',
      notes: '',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!supabase) {
      setSnackbarMessage('Unable to connect to the database. Please try again later.');
      setOpenSnackbar(true);
      return;
    }
    try {
      const payload = { ...entry };
      // Remove fields not relevant based on category
      if (category !== 'hotel') {
        delete payload.walk_rating;
        delete payload.internet_speed;
        delete payload.free_breakfast;
      }
      // Remove the 'category' field from the payload
      delete payload.category;

      // Add created_by field if user is logged in
      if (user) {
        payload.created_by = user.id;
      }

      let { data, error } = await supabase
        .from(category === 'hotel' ? 'hotels' : category === 'restaurant' ? 'restaurants' : 'attractions')
        .insert([payload])
        .select();

      if (error) throw error;

      // Add entry in recent_activity table
      const { error: activityError } = await supabase
        .from('recent_activity')
        .insert({
          action: 'Submit',
          category: category.charAt(0).toUpperCase() + category.slice(1), // Capitalize the category
          item_id: data[0].id.toString(),
          user_id: user.id,
          created_at: new Date().toISOString()
        });

      if (activityError) throw activityError;

      setSnackbarMessage('Entry submitted successfully!');
      setOpenSnackbar(true);
      // Reset form but keep the selected category
      setEntry({
        category: category,
        airport_code: '',
        name: '',
        address: '',
        rating: '',
        price_range: '',
        walk_rating: '',
        internet_speed: '',
        free_breakfast: false,
        notes: '',
        link: '',
      });
    } catch (error) {
      console.error('Error submitting entry:', error);
      setSnackbarMessage('Error submitting entry: ' + error.message);
      setOpenSnackbar(true);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEntry((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSpeedTest = async () => {
    setIsTestingSpeed(true);
    setSpeedTestResult(null);
    try {
      // Step 1: Get the configuration
      const configResponse = await axios.get('https://speed.cloudflare.com/meta');
      const { clientIp, location, isp } = configResponse.data;

      // Step 2: Perform the download test
      const startTime = Date.now();
      const downloadUrl = `https://speed.cloudflare.com/__down?bytes=100000000`; // 100MB file
      await axios.get(downloadUrl, { responseType: 'arraybuffer' });
      const endTime = Date.now();

      // Calculate speed
      const durationSeconds = (endTime - startTime) / 1000;
      const downloadSpeed = (100 / durationSeconds) * 8; // Convert to Mbps

      // Round the download speed to the nearest whole number
      const roundedSpeed = Math.round(downloadSpeed);
      setSpeedTestResult(roundedSpeed);
      
      // Convert to our format
      let speedCategory = 'No Internet';
      if (roundedSpeed >= 100) speedCategory = '100Mbps+';
      else if (roundedSpeed >= 50) speedCategory = '50Mbps+';
      else if (roundedSpeed >= 30) speedCategory = '30Mbps+';
      else if (roundedSpeed >= 15) speedCategory = '15Mbps+';
      else if (roundedSpeed >= 5) speedCategory = '5Mbps+';
      
      setEntry(prev => ({ ...prev, internet_speed: speedCategory }));
    } catch (error) {
      console.error('Error during speed test:', error);
      setSnackbarMessage('Error during speed test');
      setOpenSnackbar(true);
    } finally {
      setIsTestingSpeed(false);
    }
  };

  return (
    <Box className="max-w-2xl mx-auto">
      <Typography variant="h5" gutterBottom className="mb-10">
        Submit Entry
      </Typography>
      {user ? (
        <Typography 
          variant="body2" 
          className="text-green-500" 
          style={{ marginBottom: '2rem' }}
        >
          You're logged in as {username || user.email}. Your submission will be associated with your account.
        </Typography>
      ) : (
        <Typography 
          variant="body2" 
          className="text-yellow-500" 
          style={{ marginBottom: '2rem' }}
        >
          You're not logged in. Your submission will be anonymous. Consider logging in to track your submissions.
        </Typography>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Category Selector */}
        <FormControl fullWidth size="small">
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            onChange={handleCategoryChange}
            label="Category"
            required
            aria-label="Category Selector"
          >
            <MenuItem value="hotel">Hotel</MenuItem>
            <MenuItem value="restaurant">Restaurant</MenuItem>
            <MenuItem value="attraction">Attraction</MenuItem>
          </Select>
        </FormControl>

        {/* Airport Autocomplete */}
        <AirportAutocomplete
          value={entry.airport_code}
          onChange={(value) => setEntry((prev) => ({ ...prev, airport_code: value }))}
          label="Airport"
          required
        />

        {/* Name */}
        <TextField
          label="Name"
          name="name"
          value={entry.name}
          onChange={handleChange}
          fullWidth
          size="small"
          required
          aria-label="Name"
        />

        {/* Address */}
        <TextField
          label="Address"
          name="address"
          value={entry.address}
          onChange={handleChange}
          fullWidth
          size="small"
          required
          aria-label="Address"
        />

        {/* Link (new field) */}
        <TextField
          label="Website Link (optional)"
          name="link"
          value={entry.link}
          onChange={handleChange}
          fullWidth
          size="small"
          aria-label="Website Link"
        />

        {/* Rating (now using StarRating component) */}
        <StarRating
          name="rating"
          value={entry.rating}
          onChange={handleChange}
          label="Rating"
          required
        />

        {/* Conditional Fields for Hotel */}
        {category === 'hotel' && (
          <>
            {/* Price Range */}
            <FormControl fullWidth size="small">
              <InputLabel>Price Range</InputLabel>
              <Select
                name="price_range"
                value={entry.price_range}
                onChange={handleChange}
                label="Price Range"
                required
                aria-label="Price Range Selector"
              >
                <MenuItem value="">
                  <em>Select Price Range</em>
                </MenuItem>
                <MenuItem value="$50-100">$50-100</MenuItem>
                <MenuItem value="$100-175">$100-175</MenuItem>
                <MenuItem value="$176-250">$176-250</MenuItem>
                <MenuItem value="$251-350">$251-350</MenuItem>
                <MenuItem value="$351+">$351+</MenuItem>
              </Select>
            </FormControl>

            {/* Walk Rating (now using StarRating component) */}
            <StarRating
              name="walk_rating"
              value={entry.walk_rating}
              onChange={handleChange}
              label="Walk Rating"
              required
            />

            {/* Internet Speed */}
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
              <div className="flex-1 flex items-center">
                <Button
                  onClick={handleSpeedTest}
                  variant="outlined"
                  fullWidth
                  className="h-[40px] whitespace-nowrap"
                  disabled={isTestingSpeed}
                >
                  {isTestingSpeed ? (
                    <span>{loadingText}</span>
                  ) : speedTestResult ? (
                    <div className="flex items-center justify-between w-full">
                      <span>Result: {speedTestResult} Mbps</span>
                      <span className="text-xs ml-2">(Try Again)</span>
                    </div>
                  ) : (
                    'Test Speed'
                  )}
                </Button>
              </div>
              <div className="flex-1">
                <TextField
                  label="Internet Speed"
                  name="internet_speed"
                  value={entry.internet_speed}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  aria-label="Internet Speed"
                  placeholder="e.g., 50Mbps+"
                />
              </div>
            </div>

            {/* Free Breakfast */}
            <FormControlLabel
              control={
                <Checkbox
                  name="free_breakfast"
                  checked={entry.free_breakfast}
                  onChange={handleChange}
                  color="primary"
                  aria-label="Free Breakfast Checkbox"
                />
              }
              label="Free Breakfast"
            />
          </>
        )}

        {/* Conditional Fields for Restaurant and Attraction */}
        {(category === 'restaurant' || category === 'attraction') && (
          <>
            {/* Price Range */}
            <FormControl fullWidth size="small" required>
              <InputLabel>Price Range</InputLabel>
              <Select
                name="price_range"
                value={entry.price_range}
                onChange={handleChange}
                label="Price Range"
                required
                aria-label="Price Range Selector"
              >
                <MenuItem value="">
                  <em>Select Price Range</em>
                </MenuItem>
                {category === 'attraction' && (
                  <MenuItem value="Free">Free</MenuItem>
                )}
                <MenuItem value="$">$</MenuItem>
                <MenuItem value="$$">$$</MenuItem>
                <MenuItem value="$$$">$$$</MenuItem>
                <MenuItem value="$$$$">$$$$</MenuItem>
                <MenuItem value="$$$$$">$$$$$</MenuItem>
              </Select>
            </FormControl>
          </>
        )}

        {/* Notes */}
        <div className="relative">
          <TextField
            label="Notes"
            name="notes"
            value={entry.notes}
            onChange={handleChange}
            fullWidth
            size="small"
            multiline
            rows={3}
            placeholder="Notes (Max 150 char, e.g 'good breakfast, easy access to downtown, etc')"
            inputProps={{ maxLength: 150 }}
            aria-label="Notes"
          />
          <Typography
            variant="caption"
            className="absolute bottom-1 right-2 text-gray-500"
          >
            {entry.notes.length}/150
          </Typography>
        </div>

        <div className="flex justify-center mt-4">
          <Button
            type="submit"
            variant="contained"
            className="font-bold py-2 px-4"
            style={{ width: 'auto', minWidth: '120px' }}
            aria-label="Submit Button"
          >
            Submit
          </Button>
        </div>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
      </form>
    </Box>
  );
}

export default Submit;
