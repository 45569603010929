import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Box, TextField, Button, Paper, Snackbar, CircularProgress } from '@mui/material';

const ResetPasswordPage = ({ supabase }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const MIN_PASSWORD_LENGTH = 6;

  useEffect(() => {
    const verifyToken = async () => {
      // Check for token in URL parameters
      const params = new URLSearchParams(location.search);
      let token = params.get('token');
      let hashParams;

      // If not in URL parameters, check the hash
      if (!token && location.hash) {
        const hash = location.hash.substring(1); // Remove the '#' at the start
        hashParams = new URLSearchParams(hash);
        token = hashParams.get('access_token');
      }

      if (token) {
        try {
          // If token is from hash, it's already a valid session, just set it
          if (location.hash) {
            const { data, error } = await supabase.auth.setSession({
              access_token: token,
              refresh_token: hashParams.get('refresh_token'),
            });
            if (error) throw error;
          } else {
            // If token is from URL parameters, verify it
            const { error } = await supabase.auth.verifyOtp({ token, type: 'recovery' });
            if (error) throw error;
          }
          setIsTokenValid(true);
        } catch (error) {
          console.error('Error verifying token:', error);
          setMessage('Invalid or expired reset link. Please request a new one.');
          setOpenSnackbar(true);
        }
      } else {
        setMessage('No reset token found. Please request a new password reset link.');
        setOpenSnackbar(true);
      }
      setIsLoading(false);
    };

    verifyToken();
  }, [location, supabase.auth]);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword.length < MIN_PASSWORD_LENGTH) {
      setMessage(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`);
      setOpenSnackbar(true);
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      setOpenSnackbar(true);
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({ password: newPassword });
      if (error) throw error;
      setMessage('Password updated successfully. You can now log in with your new password.');
      setOpenSnackbar(true);
      setTimeout(() => navigate('/'), 3000);
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('Error resetting password. Please try again.');
      setOpenSnackbar(true);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="max-w-md mx-auto mt-8 p-4">
      <Paper elevation={3} className="p-6">
        <Typography variant="h5" gutterBottom>
          Reset Your Password
        </Typography>
        {isTokenValid ? (
          <form onSubmit={handleResetPassword}>
            <TextField
              fullWidth
              margin="normal"
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              helperText={`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Confirm New Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className="mt-4"
            >
              Reset Password
            </Button>
          </form>
        ) : (
          <Typography color="error">
            {message}
          </Typography>
        )}
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={message}
      />
    </Box>
  );
};

export default ResetPasswordPage;
