import React from 'react';
import { Rating, Typography, Box } from '@mui/material';

function StarRating({ name, value, onChange, label, required = false }) {
  return (
    <Box>
      <Typography component="legend">{label}{required && ' *'}</Typography>
      <Rating
        name={name}
        value={Number(value)}
        onChange={(event, newValue) => {
          onChange({ target: { name, value: newValue } });
        }}
        precision={1}
      />
    </Box>
  );
}

export default StarRating;
